<template>
    <Form
            @submit="loginUser(data.inputFormData)"
            :validation-schema="schema"
            v-slot="{ errors }"
    >
        <div class="form-group mb-3">
            <label class="label"> {{translation?.username}}</label>
            <Field
                    type="text"
                    class="form-control"
                    v-model="data.inputFormData.username"
                    :placeholder="translation.username? translation?.username: ''"
                    name="username"
            />
            <span class="text-danger">{{ errors.username }}</span>
        </div>
        <div class="form-group mb-3">
            <label class="label"> {{translation?.password}}</label>
            <password name="password"
                                      class="form-control form-control-sm"
                                      v-model="data.inputFormData.password"
                                      :placeholder="translation.password? translation?.password: ''"/>
            <span class="text-danger">{{ errors.password }}</span>
        </div>
        <div class="form-group mb-2">
            <button
                    type="submit"
                    class="form-control btn btn-primary rounded submit px-3"
            >
               {{translation?.log_in}}
            </button>
        </div>
        <div class="form-group mb-3">
            <div class="custom-control custom-checkbox my-auto flex-grow-1">
                <input type="checkbox" class="custom-control-input" id="customCheck1"/>
                <label class="custom-control-label ms-2" for="customCheck1"
                >{{translation?.remember_me}}
                </label>
            </div>
        </div>
        <div class="row">
            <div
                    class="col-xl-8 col-lg-8 col-md-8 col-sm-11 col-11 m-auto text-center"
            >
                <router-link
                        class="site-text-btn font-weight-500 m-auto"
                        :to="'/password/reset'"
                >
                 {{translation?.forgot_password}}
                </router-link>
            </div>
        </div>
        <div class="col-xl-5 col-lg-5 col-md-6 col-sm-6 col-6 m-auto py-3">
            <div class="border-bottom-span"></div>
        </div>
        <div class="row mb-3">
            <div
                    class="col-xl-8 col-lg-8 col-md-8 col-sm-11 col-11 m-auto text-center"
            >
                <router-link
                        class="site-text-btn font-weight-500 m-auto"
                        :to="'/signup'"
                >
                    {{translation?.create_an_account}}
                </router-link>
            </div>
        </div>
    </Form>
</template>

<script>
    import {Form, Field} from "vee-validate";
    import * as Yup from "yup";
    import HelperFunction from "@/common/helpers";
    import {useStore} from "vuex";
    import Password from "@/common/components/input/Password";

    export default {
        name: "login-form",
        props: ['translation'],
        components: {
            Form,
            Field,
            Password
        },
        setup() {
            const {data, showToast} = HelperFunction();
            const store = useStore();

            try {
                //VALIDATION
                const schema = Yup.object().shape({
                    username: Yup.string().required(),
                    password: Yup.string()
                        .min(6, "Password must be at least 6 characters")
                        .required("Password is required"),
                });

                //OnCLick
                async function loginUser(formData) {
                    try {
                        const loginEndpoint =
                            process.env.VUE_APP_BACKEND_URL + vueConfig.Auth.LoginEndPoint;

                        //ACTION REQUEST
                        const response = await store.dispatch("loginAction", {
                            formData: formData,
                            action: loginEndpoint,
                        });
                        // console.log(response);
                        showToast(response)
                    } catch (err) {
                        showToast(err)
                    }
                }

                return {
                    schema,
                    loginUser,
                    data,
                };
            } catch (err) {
                console.log(err);
            }
        },
    };
</script>